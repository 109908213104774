import React, { useState, useEffect } from 'react';
import './ScamEvalGames.css'; // Import the updated styles
import { Link ,useNavigate} from 'react-router-dom';  

const BalloonGame = () => {
  
  const navigate = useNavigate(); // Initialize useNavigate

  const [balloonSize, setBalloonSize] = useState(100);
  const [gameOver, setGameOver] = useState(false);
  const [burstSize, setBurstSize] = useState(0);
  const [pumpCount, setPumpCount] = useState(0);
  const [message, setMessage] = useState("Pump the balloon!");
  const [submitted, setSubmitted] = useState(false);
  const [gameDetails, setGameDetails] = useState([]);
  const [sliderValue, setSliderValue] = useState(100);

  const maxBalloonSize = 400;
  const sliderHeight = 500; // Height of the slider for positioning traces
  const [previousSliderValue, setPreviousSliderValue] = useState(100);

  useEffect(() => {
    const randomBurstSize = Math.floor(Math.random() * (400 - 150 + 1)) + 150;
    setBurstSize(randomBurstSize);
  }, []);

  useEffect(() => {
    if (gameDetails.length > 0) {
      console.log("Game Details Updated:", gameDetails);
    }
  }, [gameDetails]);

  const handleSliderChange = (event) => {
    const value = parseInt(event.target.value);
    setSliderValue(value);

  };

  const handlePump = () => {
    if (!gameOver && sliderValue > 100) {
      const increment = Math.floor(Math.random() * (20 - 5 + 1)) + 5;
      const updatedBalloonSize = Math.min(balloonSize + increment, maxBalloonSize);

      if (updatedBalloonSize >= burstSize) {
        setMessage(`Boom! The balloon burst after ${pumpCount + 1} pumps!`);
        setGameOver(true);
      } else {
        setBalloonSize(updatedBalloonSize);
        setPumpCount(pumpCount + 1);
        setMessage("Keep pumping!");
      }

      setSliderValue(100);
    
    }
  };

  const resetGame = () => {
    const result = {
      balloonSize,
      pumpCount,
      burstSize,
      message: message.includes('Boom') ? message : "Not burst",
      date: new Date().toISOString(),
    };

    setBalloonSize(100);
    setGameOver(false);
    setMessage("Pump the balloon!");
    setPumpCount(0);
    setSubmitted(false);
    setSliderValue(100);
    setBurstSize(Math.floor(Math.random() * (400 - 150 + 1)) + 150);

    console.log("Game Result Before Reset:", result);

    setGameDetails(prevDetails => [...prevDetails, result]);
  };

  const handleSubmit = () => {
    const result = {
      balloonSize,
      pumpCount,
      burstSize,
      message: message.includes('Boom') ? message : "Not burst",
      date: new Date().toISOString(),
    };

    setGameDetails(prevDetails => [...prevDetails, result]);
    setSubmitted(true);

    navigate("/start-mcq");

  };

  const handleSliderRelease = () => {
    handlePump();
  };



  return (
      <div className="game-container">
        <h1>Balloon Pumping Game</h1>
        <div className="ballon-slider-container">
          <div className="balloon" style={{ width: balloonSize, height: balloonSize }}>
            {gameOver ? "💥" : "🎈"}
          </div>
          <div className="slider-container">
            <input
              type="range"
              min="100"
              max="400"
              value={sliderValue}
              className="slider"
              onChange={handleSliderChange}
              onMouseUp={handleSliderRelease}

              onTouchEnd={handleSliderRelease}


              orient="vertical"
            />
          
          </div>
        </div>

        {gameOver && (
          <button onClick={resetGame}>
            Play Again
          </button>
        )}
        <p>{message}</p>
      {/* <p>Pumps: {pumpCount}</p> */}
      <button onClick={handleSubmit} disabled={submitted}>
        Submit and Next Game
      </button>
      </div>
  
  );
};

export default BalloonGame;
