import React, { useEffect ,useState} from "react";
import { Link ,useNavigate} from 'react-router-dom';  
import UserRegisterPopup from './support/UserRegisterPopup'; // Ensure this import path is correct
import './App.css'; // Import the CSS file
import { WhatsappShareButton, WhatsappIcon } from 'react-share';
import VerifiedExamples from './support/VerifiedExamples';
import VerificationCompo from './support/ChatComponent';



const ActionTexts = ['a message', 'a call', ' a web-link', ' a job offer', 'an investment scheme'];


const App = () => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [show, setShow] = useState(false);

  const shareUrl = "https://verifype.com"; // Your URL to share
  const shareText = "Want to protect yourself and your loved ones from fraud and scams?\n\nVerify messages, calls, emails, and more with VerifyPe.\n\nIt's quick and easy—check it out at ";

  const [isPopupOpen, setIsPopupOpen] = useState(false); // State for popup visibility
  const [emailId, setEmailId] = useState('');


  const navigate = useNavigate(); // Initialize useNavigate
  const currentYear = new Date().getFullYear(); // Get the current year

 
  // useEffect(() => {
  //   setShow(true); // Show message on mount

  //   const interval = setInterval(() => {
  //     setShow(false); // Hide message before changing text
  //     setTimeout(() => {
  //       setCurrentIndex((prevIndex) => (prevIndex + 1) % ActionTexts.length);
  //       setShow(true); // Show new message
  //     }, 500); // Match the duration of the transition (0.5s)
  //   }, 400000); // Change text every 2 seconds

  //   return () => clearInterval(interval);
  // }, [ActionTexts]);


  const handleOpenPopup = () => {
    setIsPopupOpen(true); // Show popup
};

const handleClosePopup = () => {
    setIsPopupOpen(false); // Hide popup
};

const handleInputChange = (e) => {
  // Only allow numerical input
  const value = e.target.value;
  console.log("value: ",value);
  setEmailId(value);
};


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on mount
    // Set a timeout to open the popup after 1 minute (60000 ms)
    const popupTimer = setTimeout(() => {
      setIsPopupOpen(true);
    }, 1800000);
    
  }, []);

  const handleVerifyNow = () => {
    navigate('/start-verification'); // Navigate to ChatComponent
  };

  // const handleSubscription = (event) => {
  //   event.preventDefault(); // Prevent the default form submission
  //   alert("You are subscribed to our newsletter"); // Show the alert
  //   // Additional logic for subscription can be added here
  // };

  return (
    <div>
  

      <div className="app-container">
          <div className="main-section">
          
    
          <p className="main-heading_app_small">Worried About <span style={{color:"red"}}>Fraud and Scam</span> Messages?</p>
    
          {/* <h3 className="main-heading">Power to <span className="VerifyPe">Verify</span></h3> */}
          <p className="sub-heading">Instantly verify unknown messages, conversations, job offers, investment schemes and many more!</p>
          <p className="sub-heading">Verify and Stay Protected with <span className="VerifyPe">VerifyPe.</span></p>
         
          <VerificationCompo/>
            {/* <button className="cta-button" onClick={handleVerifyNow}>
              Verify Now
            </button> */}
          </div>

{/* 
          <div className="sub-section">
          <h2 className="mission-heading">Get Your Fraud Awareness Score</h2>
          <p className="sub-heading">Think you're invincible against scams and fraud?</p> 
          <p className="sub-heading">Put your knowledge to the test and find out!</p>
          <div style={{ textAlign: 'center', marginTop: '30px' }}>
              <label style={{ fontSize: '18px', paddingBottom: '30px', fontWeight: 'bold', textDecoration: 'underline' }}>
              Coming Soon
              </label>
          </div>
        <Link to="/get-fraud-score">
          <button className="cta-button">Coming Soon</button>
        </Link>
          </div> */}


          <div className="sub-section">
          <p className="main-heading_app_small">Stressed About <span style={{color:"red"}}>Unauthorized Loans and Identity Theft ?</span></p>
          <p className="sub-heading">We constantly checks with credit bureaus for any new loan activities in your name. </p> 
          <ul className="sub-heading-list">
                <li>Get Notified Instantly – Whenever a new loan is detected, you’ll receive an alert.</li>
                <li>Verify or Report – If you didn’t apply for this loan, report it directly on our platform.</li>
                <li>We Take Action – We’ll work with the credit bureau and issuing bank to resolve the issue.</li>
            </ul>
            <button className="cta-button" onClick={() => setIsPopupOpen(true)}>Interested ?</button>
          </div>

          <VerifiedExamples />
          
        <footer className="footer">
          <p>&copy; {currentYear} VerifyPe. All rights reserved.</p>
        </footer>
      </div>

      {/* <div className="mission-section">
        <div className="mission">
          <h2 className="mission-heading">Our Mission</h2>
          <p className="sub-heading">We're on a mission to make the online world safer for everyone.</p>
          <p className="sub-heading">At VerifyPe, everyone is invited to verify information with ease, free from any doubts, hesitations or judgements.</p>
          <p className="sub-heading">Join us in our quest to put an end to scams and fraud targeting innocent individuals.</p>

        </div>
      </div> */}

      {/* <div className="subscription-section">
      <h2 className="mission-heading">Subscribe</h2>
      <p className="sub-heading">Subscribe to our newsletter to stay updated on Fraud trends</p>
        <div>
          <input
            type="email"
            placeholder="Enter a valid email address"
            required
            onChange={handleInputChange}
          />
          <button type="submit" className="cta-button" onClick={handleOpenPopup}>Submit</button>
          </div>
      </div> */}



      <UserRegisterPopup isOpen={isPopupOpen} emailId={emailId} onClose={handleClosePopup} />

      <div className="whatsapp-share">
      <WhatsappShareButton
        url={shareUrl}
        title={shareText}
      >
        <WhatsappIcon round />
      </WhatsappShareButton>
    </div>

    </div>
  );
};



export default App;