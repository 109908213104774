import React, { useState } from 'react';
import './VerifiedExamples.css';

// Example data - replace with actual data fetched from your API or database
const verifiedMessages = [

  { id: 1, message: "HSBC: We have temporarily restricted access on your account due to suspicious activity, to re-authenticate visit:hsbc-mt-assistance.com.", verdict: "Confirmed Fraud" ,Reason:"Impersonation of a legitimate bank with a fake URL",Crime:"Phishing Scam"},
  { id: 2, message: "There is a part-time job, you can use your mobile phone to operate at home, you can earn 200-3000 rupees a day, 10-30 minutes a day new users join to get you 50 rupees, waiting for you to join. Reply 1 and long click the link to join us asap. http://wame.wp-e.com/api/tg/wa/8w36ceh.", verdict: "Highly Suspicious" ,Reason:"Unrealistic Earnings and Urgency",Crime:"Job Scam"},
  { id: 3, message: "Loan for Low Credit Scores sr9.in/mb7d", verdict: "Suspicious", Reason:"Lack of verification for the short URL and potential misleading offers.",Crime:"Loan Scam" },

  { id: 4, message: "Hey, Just register yourself and get 5 liter Nerolac paint for free on Registration Click here :- nc.1on.in/je0XYcuh7", verdict: "Suspicious" ,Reason:"Promises free items with questionable legitimacy.",Crime:"Free Gift Scam"},
  { id: 5, message: "Hi, Rose I need you to help me get Amazon gift cards for some cancer patients that I promised as a gift. I really can't do it now. Can you get it from there? I'll refund you ASAP!", verdict: "Highly Suspicious" ,Reason:" Request for gift cards with a promise of refund is a common scam tactic.",Crime:"Gift Card Scam"},
  { id: 6, message: "Rs. 45,999 has been Credited in your Demat A/c Wallet. Use this & Buy Shares Check Now:-  nc.1on.in/eE3pcysBB", verdict: "Highly Suspicious", Reason:"Unsolicited financial notification with unverified links",Crime:"Phishing Scam" },
 
  { id: 7, message: "India Post: Your parcel has arrived at the warehouse but cannot be delivered due to incomplete address information. Please use the link below to confirm your address within 12 hours.https://bit.ly/3VRbYiO (Answer Y and click the link above, or copy the link and open in Safari) The India Post team wishes you a wonderful day.", verdict: "Highly Suspicious" ,Reason:"Urgency and incomplete address request are common phishing tactics.",Crime:"Phishing Scam"},
  { id: 8, message: "Congrats 982146XX, INR. 10,000 to INR. 2,00,000 loan is ready for credited to your Bank A/c on 11-10-2024. Apply & Claim http://kx9.in/MOBIKW/Gq2i4N -MobiKwik", verdict: "Highly Suspicious" ,Reason:"High loan amount with urgency to apply",Crime:"Loan Scam"},
  { id: 9, message: "Hello Customer,Your P YTM account KYC is expired & Blocked today.Please Call Customer Care: 8391969141 Regards, P YTM TE M", verdict: "Suspicious" ,Reason:"Urgent action requested for account issue.",Crime:"KYC Phishing Attempt"},

  { id: 10, message: "Congrats, Y0UR Received Rs.450000 L0AN is Approve on 17-O9-2O24. Zero documentation. Withdraw direct T0 Y0UR A/c. SR3.in/O13C0-2i49i67AF8C.", verdict: "Highly Suspicious" ,Reason:"Promotes a loan with zero documentation and large amount.",Crime:"Loan Scam"},

];

const VerifiedExamples = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    if (currentIndex < verifiedMessages.length - 3) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <section className="examples-container">
      <p className="examples-heading">Verified by <span className="VerifyPe">VerifyPe.</span></p>
      <div className="slider-container">
        <div className="message-list">
          {verifiedMessages.slice(currentIndex, currentIndex + 3).map((item) => (
            <div key={item.id} className="message-item">
              <p className="message-text">{item.message}</p>
              <p className={`verdict fraud`}>
                <strong>Verdict:</strong> {item.verdict}
              </p>
              <p><strong>Reason:</strong> {item.Reason}</p>
              <p><strong>Potential Cyber Crime:</strong> {item.Crime}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="nav-buttons-container">
        <button onClick={prevSlide} disabled={currentIndex === 0} className="nav-button">◀</button>
        <button onClick={nextSlide} disabled={currentIndex >= verifiedMessages.length - 3} className="nav-button">▶</button>
      </div>
    </section>
  );
};

export default VerifiedExamples;
