import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import './RenderingVerdict.css';

const Report = () => {
  const navigate = useNavigate();

  
  const [step, setStep] = useState(1);
  const [uniqueId, setUniqueId] = useState('');
  const [suspiciousReason, setSuspiciousReason] = useState('');
  const [orginalSuspiciousReason, setOrginalSuspiciousReason] = useState('');

  const [selectedChannel, setSelectedChannel] = useState('');

  const channelList = ['Phone Number', 'Email Id', 'Web-link', 'Other'];

  const handleChannelClick = (channel) => {
    setSelectedChannel(channel);
    setStep(2);
  };

  const handleSubmit = async () => {
    if (!isFormValid()) return;
  
    try {
      const response = await fetch(`${process.env.REACT_APP_VERIFYPE_BACKEND}/reporting-suspect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          selectedChannel,
          uniqueId,
          suspiciousReason,
          orginalSuspiciousReason,
        }),
      });

      const result = await response.json();
  
      if (response.ok) {
        alert(result.message);
        navigate('/');
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error("Error submitting report:", error);
      alert("There was a problem submitting your report. Please try again.");
    }
  };
  

  const isFormValid = () => {
    return selectedChannel && uniqueId && suspiciousReason && orginalSuspiciousReason;
  };
  

  return (
    <div className="report-container">
      {step === 1 && (
        <div className="report-section">
          <h2>What would you like to Report?</h2>
          <div className="report-options">
            {channelList.map((channel, index) => (
              <button
                key={index}
                className={`channel-button ${selectedChannel === channel ? 'selected' : ''}`}
                onClick={() => handleChannelClick(channel)}
              >
                {channel}
              </button>
            ))}
          </div>
        </div>
      )}
      
      {step === 2 && (
        <div className="report-section">
          <h2>Provide Details</h2>
          <div className="report-details">
            <div className="report-detail">
              <label htmlFor="unique-id">What's the Suspect's unique ID (e.g., Phone number, Sender ID, Email ID)?</label>
              <input
                type="text"
                id="unique-id"
                value={uniqueId}
                onChange={(e) => setUniqueId(e.target.value)}
              />
            </div>
            <div className="report-detail">
              <label htmlFor="reason">Why do you think it is suspicious?</label>
              <textarea
                id="reason"
                value={suspiciousReason}
                onChange={(e) => setSuspiciousReason(e.target.value)}
              ></textarea>
            </div>
            <div className="report-message">
              <p>Please copy/paste the message which indicates the suspicious activity:</p>
              <textarea
                id="reason"
                value={orginalSuspiciousReason}
                onChange={(e) => setOrginalSuspiciousReason(e.target.value)}
                placeholder="Copy/paste the suspicious message here..."
              ></textarea>
            </div>
            <button 
               className={`cta-button ${isFormValid() ? '' : 'disabled'}`}
               disabled={!isFormValid()}
               onClick={handleSubmit}>
            Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Report;
