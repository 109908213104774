import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ChatComponent.css'; // Import the CSS file

const ChatComponent = () => {
  const navigate = useNavigate();

  const handleOptionClick = (option) => {
    if (option === 'image') {
      navigate('/verify-image');  // Navigate to the Image Verification page
    } else if (option === 'text') {
      navigate('/verify-text');   // Navigate to the Text Verification page
    }
  };

  return (
    <div className="container">
      <div className='section_action_container'>
        <div className="section_action" onClick={() => handleOptionClick('image')}>
          <h2>Verify From Image</h2>
          <p>Upload a Screenshot of the Suspicious Text Message from anywhere (SMS, WhatsApp, Email etc.)</p>
        </div>
        <div className="section_action" onClick={() => handleOptionClick('text')}>
          <h2>Verify Text</h2>
          <p>Just paste the text from anywhere (SMS, WhatsApp, Email etc.)  Or Enter <span style={{ textDecoration: 'underline'}}>Suspicious Web-link/Organisation Name</span></p>
        </div>
      </div>
    </div>
  );
};

export default ChatComponent;
