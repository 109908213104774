// FraudTrends.js
import React, { useState,useEffect } from 'react';
import './FraudTrends.css'; // Import CSS for styling

const articles = [
  {
    title: "Understanding Phishing Scams",
    subtitle: "How to Protect Yourself from Phishing Attacks",
    images: [
      "phishing_scam.jpeg"
    ],
    contents: [
      "Phishing scams are deceptive attempts to obtain sensitive information from individuals by masquerading as a trustworthy entity. Phishing can occur through emails, text messages, or fake websites that seem legitimate.",
      "The primary goal of phishing is to trick you into providing personal information such as passwords, credit card numbers, or social security numbers. Why is phishing so dangerous? Because it can lead to identity theft, financial loss, and unauthorized access to your accounts.",
      "To protect yourself from phishing attacks, be cautious of unsolicited communications that request personal information. Always verify the sender's email address or phone number, and avoid clicking on suspicious links.",
      "Ensure your security software is up-to-date and educate yourself on common phishing tactics to stay one step ahead of scammers."
    ],
    createdDate: "2024-08-29",
    author: "admin",
    "knownCases": ["sdddd","dddd"]
  },
  {
    title: "Recognizing Investment Frauds",
    subtitle: "Avoiding the Pitfalls of Fake Investment Schemes",
    images: [
      "investment_scam.jpeg"
    ],
    contents: [
      "Investment frauds are deceptive schemes that promise high returns with little to no risk. These fraudulent schemes often target individuals by offering unrealistic returns on investments, often claiming to have insider knowledge or guaranteed profits.",
      "Why is investment fraud a concern? Because it can result in significant financial losses and undermine your financial security. Fraudsters use various tactics to lure investors, including fake websites, fake testimonials, and high-pressure sales tactics.",
      "To protect yourself from investment fraud, research any investment opportunity thoroughly before committing funds. Look for red flags such as promises of guaranteed returns, lack of transparency, or unregistered investment advisors.",
      "Consult with a financial advisor and verify the legitimacy of the investment through regulatory bodies."
    ],
    createdDate: "2024-08-29",
    author: "admin",
    "knownCases": ["sdddd","dddd"]

  },
  {
    title: "Avoiding Credit Card Fraud",
    subtitle: "Tips to Keep Your Credit Card Information Safe",
    images: [
      "5.jpeg"
    ],
    contents: [
      "Credit card fraud involves unauthorized use of your credit card information to make purchases or withdraw funds. Fraudsters can obtain your credit card details through various means, including phishing, skimming, or data breaches.",
      "Credit card fraud is concerning because it can lead to significant financial losses and impact your credit score. Fraudulent transactions can be difficult to resolve and may require time and effort to correct.",
      "To protect your credit card information, use secure websites for online purchases, monitor your credit card statements regularly for unauthorized charges, and report any suspicious activity immediately.",
      "Additionally, consider using virtual credit cards for online transactions and enable alerts for your credit card activity to stay informed of any changes."
    ],
    createdDate: "2024-08-29",
    author: "admin",
    "knownCases": ["admin"]
  },

  {
    title: "Spotting Fake Online Reviews",
    subtitle: "How to Identify and Avoid Misleading Reviews",
    images: [
      "7.jpeg"
    ],
    contents: [
      "Fake online reviews are fabricated or manipulated reviews designed to mislead consumers. These reviews can be positive or negative, and are often used to influence purchasing decisions or discredit competitors.",
      "Why are fake reviews problematic? They distort consumer perceptions and can lead to poor purchasing decisions, affecting both consumers and businesses.",
      "To spot fake reviews, look for patterns in the language and check for reviews that are overly positive or negative without providing specific details. Use multiple sources to verify product or service quality and read reviews from verified purchasers.",
      "Consider using review aggregators or trusted review platforms that have mechanisms in place to detect and filter out fake reviews."
    ],
    createdDate: "2024-08-29",
    author: "admin",
    "knownCases": ["sdddd","dddd"]
  },
  {
    title: "Recognizing Social Engineering Attacks",
    subtitle: "Protecting Yourself from Manipulative Tactics",
    images: [
      "8.jpeg"
    ],
    contents: [
      "Social engineering attacks involve manipulating individuals into divulging confidential information or performing actions that compromise security. These attacks can take many forms, including phishing, pretexting, and baiting.",
      "The risk of social engineering is that it preys on human psychology rather than exploiting technical vulnerabilities. Attackers use deceitful tactics to gain trust and manipulate their targets.",
      "To recognize social engineering attacks, be skeptical of unsolicited requests for sensitive information. Verify the identity of the requester and be cautious of any urgent or high-pressure tactics.",
      "Educate yourself and your team about common social engineering tactics and implement policies to handle sensitive information securely."
    ],
    createdDate: "2024-09-02",
    author: "admin",
    "knownCases": ["sdddd","dddd"]
  },
  {
    title: "Protecting Against Identity Theft",
    subtitle: "Steps to Safeguard Your Personal Information",
    images: [
      "9.jpeg"
    ],
    contents: [
      "Identity theft occurs when someone uses your personal information without your permission to commit fraud or other crimes. This can include using your identity to open accounts, make purchases, or obtain loans.",
      "The impact of identity theft can be severe, leading to financial loss, damaged credit, and a long recovery process. Victims may face legal and financial challenges as they work to restore their identity and credit.",
      "To protect yourself from identity theft, use strong, unique passwords for your accounts, monitor your credit reports regularly, and be cautious about sharing personal information.",
      "Consider using identity theft protection services and placing a fraud alert on your credit reports if you suspect that your information has been compromised."
    ],
    createdDate: "2024-09-01",
    author: "admin",
    "knownCases": ["sdddd","dddd"]
  },
  {
    title: "Avoiding Online Shopping Scams",
    subtitle: "How to Shop Safely on the Internet",
    images: [
      "10.jpeg"
    ],
    contents: [
      "Online shopping scams involve fraudulent schemes designed to deceive consumers into making purchases from fake or misleading websites. These scams can result in financial loss and personal information theft.",
      "The danger of online shopping scams lies in their ability to appear legitimate while deceiving buyers into paying for goods or services that do not exist or are substandard.",
      "To avoid online shopping scams, shop only from reputable websites, check for secure payment methods, and read reviews from other customers. Look for signs of a secure connection, such as 'https' in the URL and a padlock icon.",
      "Be cautious of deals that seem too good to be true and verify the legitimacy of the seller before making a purchase."
    ],
    createdDate: "2024-08-31",
    author: "admin",
    "knownCases": ["sdddd","dddd"]
  },
  {
    title: "Understanding Tax Scams",
    subtitle: "How to Recognize and Avoid Tax Fraud",
    images: [
      "11.jpeg"
    ],
    contents: [
      "Tax scams involve fraudulent schemes aimed at deceiving individuals or businesses during tax season. These scams can include phishing emails, fake tax authorities, or fraudulent tax preparation services.",
      "The risk of tax scams is that they can result in financial loss, identity theft, and complications with tax filings. Scammers may use various tactics to create a sense of urgency or exploit fear of tax consequences.",
      "To recognize tax scams, be cautious of unsolicited communications that ask for personal information or payment. Verify the authenticity of tax authorities and use reputable tax preparation services.",
      "File your taxes using secure methods and report any suspicious activity to the appropriate authorities."
    ],
    createdDate: "2024-08-30",
    author: "admin",
    "knownCases": ["sdddd","dddd"]

  },
  {
    title: "Avoiding Romance Scams",
    subtitle: "Protecting Yourself from Fraudulent Online Relationships",
    images: [
      "romance_scam.jpeg"
    ],
    contents: [
      "Romance scams involve fraudulent schemes where scammers create fake online relationships to exploit individuals emotionally and financially. These scams often involve pretending to be someone else and manipulating the victim into providing money or personal information.",
      "Romance scams can have a significant emotional impact, leading to financial loss and psychological distress. Scammers use emotional manipulation to build trust and exploit the victim's feelings.",
      "To protect yourself from romance scams, be cautious of individuals who ask for money or personal information early in the relationship. Verify the identity of anyone you meet online and be wary of requests for financial assistance.",
      "Educate yourself about common romance scam tactics and report any suspicious activity to the appropriate authorities."
    ],
    createdDate: "2024-08-29",
    author: "admin",
    "knownCases": ["sdddd","dddd"]
  },
  {
    title: "Recognizing Fake Charity Scams",
    subtitle: "How to Verify the Legitimacy of Charitable Organizations",
    images: [
      "13.jpeg"
    ],
    contents: [
      "Fake charity scams involve fraudulent organizations that solicit donations under the guise of charitable causes. These scams exploit the goodwill of individuals to steal money intended for legitimate charities.",
      "The risk of fake charity scams is that they divert funds away from genuine causes and can result in financial loss for donors. Scammers use emotional appeals and high-pressure tactics to solicit donations.",
      "To recognize fake charities, research the organization thoroughly, check for accreditation from reputable charity watchdogs, and verify the legitimacy of fundraising campaigns.",
      "Avoid donating to organizations that use aggressive tactics or lack transparency. Use established charity evaluation platforms to ensure your donations go to reputable causes."
    ],
    createdDate: "2024-08-28",
    author: "admin",
    "knownCases": ["sdddd","dddd"]

  }
];


const FraudTrends = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

   // Calculate the number of pages
   const totalPages = Math.ceil(articles.length / itemsPerPage);

   // Get the current page articles
   const getPaginatedArticles = () => {
     const startIndex = (currentPage - 1) * itemsPerPage;
     const endIndex = startIndex + itemsPerPage;
     return articles.slice(startIndex, endIndex);
   };

   // Handle pagination change
  const handlePageChange = (page) => {
    handleToggle();
    setCurrentPage(page);
  };

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
    
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on mount
  }, []);

  const placeholderImage = "fraudcloud.jpeg"; // Replace with your placeholder image URL

  return (
    <div className="fraud-trends">
      <h1>Fraud & Scam Trends</h1>
      <ul className="article-list">
        {getPaginatedArticles().map((article, index) => (
          <li key={index} className="article-item">
            <label className="title">{article.title}</label>
            <label className="subtitle">{article.subtitle}</label>
            <button
              className="read-more"
              onClick={() => handleToggle(index)}
            >
              {expandedIndex === index ? "Read Less" : "Read More"} &gt;
            </button>
            {expandedIndex === index && (
              <div className="article-details">
                {/* Check if the article has an image name, else use placeholder */}
                <div className="images">
                <img
                    src={article.images.length > 0 ? article.images[0] : placeholderImage}
                  onError={(e) => e.target.src = placeholderImage}
                  alt="Article"
                />
	
                </div>
                <div className="content">
                  {article.contents.map((paragraph, paraIndex) => (
                    <p key={paraIndex}>{paragraph}</p>
                  ))}
                </div>
                <div className="meta">
                  <span className="date">Published on: {article.createdDate}</span>
                  <span className="author">Author: {article.author}</span>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
  
  
};

export default FraudTrends;