import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './RenderingVerdict.css';

const ContactUs = () => {
  const navigate = useNavigate();
  
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async () => {
    if (!isFormValid()) return;
  
    try {
        const response = await fetch(`${process.env.REACT_APP_VERIFYPE_BACKEND}/insert-query`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
          mobile,
          message,
        }),
      });

      const result = await response.json();
  
      if (response.ok) {
        alert(result.message);
        navigate('/');
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error("Error submitting contact form:", error);
      alert("There was a problem submitting your message. Please try again.");
    }
  };
  
  const isFormValid = () => {
    return name && email && mobile && message;
  };

  return (
    <div className="report-container">
      <div className="report-section">
        <h2>Contact Us</h2>
        <div className="report-details">
          <div className="report-detail">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="report-detail">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="report-detail">
            <label htmlFor="mobile">Mobile Number</label>
            <input
              type="tel"
              id="mobile"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              required
                />
          </div>
          <div className="report-detail">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message here..."
              required
            ></textarea>
          </div>
          <button 
             className={`cta-button ${isFormValid() ? '' : 'disabled'}`}
             disabled={!isFormValid()}
             onClick={handleSubmit}>
          Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
