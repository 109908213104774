export const showLoading = () => {
    // Create overlay to block access to the background
    const overlayDiv = document.createElement('div');
    overlayDiv.id = 'global-overlay';
    overlayDiv.style.position = 'fixed';
    overlayDiv.style.top = '0';
    overlayDiv.style.left = '0';
    overlayDiv.style.width = '100%';
    overlayDiv.style.height = '100%';
    overlayDiv.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    overlayDiv.style.zIndex = '999';

    // Create loading div
    const loadingDiv = document.createElement('div');
    loadingDiv.id = 'global-loading';
    loadingDiv.innerHTML = '<span>Always be good...</span>';
    loadingDiv.style.position = 'fixed';
    loadingDiv.style.top = '50%';
    loadingDiv.style.left = '50%';
    loadingDiv.style.transform = 'translate(-50%, -50%)';
    loadingDiv.style.padding = '20px';
    loadingDiv.style.backgroundColor = '#4caf50';
    loadingDiv.style.color = 'white';
    loadingDiv.style.borderRadius = '8px';
    loadingDiv.style.zIndex = '1000';

    // Append overlay and loading div to the body
    document.body.appendChild(overlayDiv);
    document.body.appendChild(loadingDiv);
};

// Call hideLoading to remove the overlay and loading div
export const hideLoading = () => {
    const overlayDiv = document.getElementById('global-overlay');
    const loadingDiv = document.getElementById('global-loading');

    if (overlayDiv) {
        document.body.removeChild(overlayDiv);
    }

    if (loadingDiv) {
        document.body.removeChild(loadingDiv);
    }
};
