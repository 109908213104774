import React, { useState ,useEffect} from "react";
import { Link ,useNavigate} from 'react-router-dom';  

const questions = [
  {
    questionText: "You receive an unexpected email saying you've won a cash prize, but you need to provide your bank details to claim it. What do you do?",
    answerOptions: [
      { answerText: "Provide the details immediately to claim the prize", score: 0 },
      { answerText: "Ignore the email, as it’s likely a scam", score: 10 },
      { answerText: "Click the link to check if the email looks legitimate", score: 3 },
      { answerText: "Respond asking for more details", score: 5 },
    ],
  },
  {
    questionText: "A pop-up on your computer says your system is infected, and you must call a support number to fix it. What is your next step?",
    answerOptions: [
      { answerText: "Call the number to resolve the issue quickly", score: 0 },
      { answerText: "Ignore the pop-up and run your own antivirus software", score: 10 },
      { answerText: "Provide your personal information to the support agent on the call", score: 2 },
      { answerText: "Restart your computer and check for any problems yourself", score: 8 },
    ],
  },
  {
    questionText: "A job offer promises an extremely high salary for very little work but asks for a small upfront fee for processing. What do you do?",
    answerOptions: [
      { answerText: "Pay the fee and accept the job offer", score: 0 },
      { answerText: "Research the company and contact them directly", score: 10 },
      { answerText: "Ask a friend if they think it’s a good opportunity", score: 6 },
      { answerText: "Ignore the offer, as it’s likely a scam", score: 8 },
    ],
  },
  {
    questionText: "You receive a call claiming to be from your bank asking for your account number to verify unusual activity. What should you do?",
    answerOptions: [
      { answerText: "Provide the account number to resolve the issue", score: 0 },
      { answerText: "Hang up and call your bank using the official number", score: 10 },
      { answerText: "Ask for more details to make sure the caller is legitimate", score: 5 },
      { answerText: "Provide only part of your account number", score: 2 },
    ],
  },
  {
    questionText: "A website is offering a huge discount on a popular item, but you must pay by wire transfer or gift card. What do you do?",
    answerOptions: [
      { answerText: "Go ahead and pay since the discount is too good to miss", score: 0 },
      { answerText: "Research the website and look for customer reviews", score: 10 },
      { answerText: "Ask the seller if you can pay using a credit card", score: 5 },
      { answerText: "Contact customer support to verify the payment options", score: 6 },
    ],
  },
  {
    questionText: "You receive a message from a friend on social media asking for money to be transferred to a new account. What should you do?",
    answerOptions: [
      { answerText: "Send the money to help your friend immediately", score: 0 },
      { answerText: "Call your friend directly to verify the request", score: 10 },
      { answerText: "Ask for more information via social media", score: 4 },
      { answerText: "Send a small amount to verify if the request is real", score: 2 },
    ],
  },
  {
    questionText: "You receive a call claiming you owe back taxes, and legal action will be taken unless you pay immediately. How do you respond?",
    answerOptions: [
      { answerText: "Pay immediately to avoid legal trouble", score: 0 },
      { answerText: "Hang up and contact the tax authorities directly", score: 10 },
      { answerText: "Ask for more information about the legal action", score: 3 },
      { answerText: "Negotiate a smaller payment amount", score: 2 },
    ],
  },
  {
    questionText: "You are browsing online, and a website asks you to enter personal information to verify your identity for a prize. What do you do?",
    answerOptions: [
      { answerText: "Enter the information to claim the prize", score: 0 },
      { answerText: "Close the website immediately", score: 10 },
      { answerText: "Enter only some of the information", score: 4 },
      { answerText: "Check the website for more details", score: 5 },
    ],
  },
  {
    questionText: "You are approached with an investment opportunity that promises guaranteed returns with little risk. What should you do?",
    answerOptions: [
      { answerText: "Invest immediately to take advantage of the opportunity", score: 0 },
      { answerText: "Research the company and the opportunity thoroughly", score: 10 },
      { answerText: "Ask friends or family for their opinion", score: 5 },
      { answerText: "Invest a small amount to test the waters", score: 3 },
    ],
  },
  {
    questionText: "A family member contacts you saying they need money for an emergency and can’t talk on the phone. What is your next step?",
    answerOptions: [
      { answerText: "Send money immediately to help them", score: 0 },
      { answerText: "Call them directly to verify the emergency", score: 10 },
      { answerText: "Ask for more details via text", score: 4 },
      { answerText: "Send a small amount first to confirm", score: 3 },
    ],
  },
];

const QuizApp = () => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [totalScore, setTotalScore] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [quizStarted, setQuizStarted] = useState(false);
    const [timeLeft, setTimeLeft] = useState(30); // Set the initial time to 30 seconds
  
    // Start the countdown when the quiz is started
    useEffect(() => {
      if (quizStarted && timeLeft > 0) {
        const timer = setInterval(() => {
          setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);
        return () => clearInterval(timer);
      }
  
      if (timeLeft === 0 && !showScore) {
        setShowScore(true); // Show score when time runs out
      }
    }, [quizStarted, timeLeft, showScore]);
  
    const handleStartQuiz = () => {
      setQuizStarted(true);
      setTimeLeft(30); // Reset timer for each new quiz
    };
  
    const handleAnswerOptionClick = (score) => {
      setTotalScore(totalScore + score);
  
      const nextQuestion = currentQuestion + 1;
      if (nextQuestion < questions.length) {
        setCurrentQuestion(nextQuestion);
      } else {
        setShowScore(true); // Show score if all questions are answered
      }
    };
  
    return (
      <div className="app">
        {!quizStarted ? (
          <div className="start-section">
            <h2>You have 30 seconds to complete 10 questions.</h2>
            <button onClick={handleStartQuiz}>Start Quiz</button>
          </div>
        ) : showScore ? (
          <div className="score-section">
            <h2>Your total score is: {totalScore}</h2>
            {totalScore > 80 ? (
              <p>You have strong awareness about scams. You're less likely to fall for one!</p>
            ) : totalScore > 60 ? (
              <p>You have good awareness with minor vulnerabilities.</p>
            ) : totalScore > 40 ? (
              <p>You have moderate awareness, but be cautious in certain situations.</p>
            ) : totalScore > 20 ? (
              <p>You have low awareness of scams. You are at Risk.</p>
            ) : (
              <p>Your awareness of scams is poor. Be extra careful!</p>
            )}
          </div>
        ) : (
          <>
            <div className="timer-section">
              <h3>Time Remaining: {timeLeft} seconds</h3>
            </div>
            <div className="question-section">
              <div className="question-count">
                <span>Question {currentQuestion + 1}</span>/{questions.length}
              </div>
              <div className="question-text">{questions[currentQuestion].questionText}</div>
            </div>
            <div className="answer-section">
              {questions[currentQuestion].answerOptions.map((answerOption, index) => (
                <button
                  key={index}
                  onClick={() => handleAnswerOptionClick(answerOption.score)}
                >
                  {answerOption.answerText}
                </button>
              ))}
            </div>
          </>
        )}
      </div>
    );
  };
  
  export default QuizApp;
  
  