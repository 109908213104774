import React, { useState, useEffect } from 'react';
import './UserRegisterPopup.css';
import { hideLoading, showLoading } from './Loading';

const UserRegisterPopup = ({ isOpen, emailId, onClose }) => {
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState(''); // Initialize with mobileNumber
    const [email, setEmail] = useState(emailId || '');
    const [location, setLocation] = useState('');
    const [message, setMessage] = useState('');

    // Update the email state if emailId prop changes
    useEffect(() => {
        setEmail(emailId || '');
    }, [emailId]);

    const handleMobileChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, '');

        console.log(" mobile number : ",value);

        setMobile(value);
    };
      
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form Submitted');
        console.log('Name:', name);
        console.log('Mobile:', mobile);
        console.log('Email:', email);
        console.log('Location:', location);

        const userData = {
            email: email,
            mobile: mobile,
            name: name,
            // location: location,
        };

        console.log("userData  :", userData);
  
        try {
            showLoading();
            // Fetch API call to your Flask backend
            const response = await fetch(`${process.env.REACT_APP_VERIFYPE_BACKEND}/add-user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            // Convert response to JSON
            const result = await response.json();

            if (response.ok) {
                alert(result.message);
                setMessage('User added successfully');
            } else {
                setMessage(`Error: ${result.error}`);
            }
            console.log("response  : ", response);

        } catch (error) {
            hideLoading();
            alert("We are not able to process your request right now.");
            console.log('Error:', error);
            setMessage('Error: Unable to add user');
        } finally {
            hideLoading();
            console.log('Error: error ');
        }

        // Optionally, reset form fields
        setName('');
        setEmail(emailId || ''); // Reset to the initial mobileNumber or empty string
        setMobile('');
        setLocation('');

        onClose(); // Close the popup after submission
    };

    // Determine if the form can be submitted
    const canSubmit = name && mobile && email; // Add location if it becomes required

    if (!isOpen) return null;

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <button className="remove-button" onClick={onClose}>X</button>
                <h2>Connect with Us for More Information</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder=" "
                        />
                        <label htmlFor="name">Name</label>
                    </div>
                    <div className="form-group">
                        <input
                            type="tel"
                            id="mobile"
                            value={mobile} // Bind to mobile state
                            onChange={handleMobileChange} // Use the function here
                            pattern="^[+0-9]*$"
                            maxLength={13} // Maximum length of 13 digits
                            minLength={10}
                            placeholder=" "
                        />
                        <label htmlFor="mobile">Mobile Number</label>
                    </div>
                    <div className="form-group">
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder=" "
                        />
                        <label htmlFor="email">Email</label>
                    </div>
                    {/* <div className="form-group">
                        <input
                            type="text"
                            id="location"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            placeholder=" "
                        />
                        <label htmlFor="location">Location</label>
                    </div> */}
                    <button 
                        type="submit" 
                        className='cta-button'
                        disabled={!canSubmit} // Disable button if canSubmit is false
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default UserRegisterPopup;
