import React, { useContext, useState ,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import './TopBar.css';

const TopBar = () => {
  const { isLoggedIn, handleLogout } = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);



  const navigate = useNavigate(); // Initialize useNavigate
  const handleLogoClick = () => {
    navigate('/'); // Redirect to home page
  };

  const handleLogoutClick = () => {
    handleLogout();
    setIsMenuOpen(false); // Close the menu on logout
  };

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="top-bar">
      <div className="logo" onClick={handleLogoClick} role="button" aria-label="Go to Home">
        <img src='logo.svg' alt="VerifyPe Logo"/>
        <span>VerifyPe</span>
      </div>

      <div 
        className="hamburger-menu" 
        onClick={toggleMenu} 
        aria-label="Toggle menu"
      >
        <div className="hamburger-icon"></div>
        <div className="hamburger-icon"></div>
        <div className="hamburger-icon"></div>
      </div>

      <div className={`links ${isMenuOpen ? 'open' : ''}`}>
        <Link to={"/" } aria-label="Go to Home" onClick={closeMenu}>
          Home
        </Link>

            <Link to="/start-Reporting" aria-label="start-Reporting" onClick={closeMenu}>
              Report
            </Link>
            <Link to="/fraud-trends" aria-label="fraud-trends" onClick={closeMenu}>
              Fraud Trends
            </Link>
            <Link to="/contact-us" aria-label="contact-us" onClick={closeMenu}>
              Contact Us
            </Link>
            {/* <Link 
              to="#" 
              onClick={handleLogoutClick} 
              aria-label="Logout"
              className="logout-link"
            >
              Logout
            </Link> */}
      
      </div>
    </div>
  );
};

export default TopBar;
