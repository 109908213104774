import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ChatComponent.css';
import { showLoading, hideLoading } from './Loading';

const VerifyText = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ text: '' });
  const textareaRef = useRef(null);

  const handleVerifyNow = async () => {
    const data = new FormData();
    data.append('message_to_verify', formData.text);

    // Remove whitespace and special characters
    const cleanedText = formData.text.replace(/[^a-zA-Z0-9]/g, '');

    // Check if the cleaned text contains only numbers
    if (/^\d+$/.test(cleanedText)) {
        alert("Please provide more information. Phone number alone is not sufficient to verify.");
        return;
    }

    // Check if the text has fewer than 10 characters or less than 3 words
    if (formData.text.length < 10) {
        alert("Text is too short. Please provide more context.");
        return;
    }


    if (!formData.text.trim()) {
      alert("Please enter the text to verify. It should not be blank.");
      return;
    }

    try {
      showLoading();
      const response = await fetch(`${process.env.REACT_APP_VERIFYPE_BACKEND}/fraud-detection`, {
        method: 'POST',
        body: data,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const response_data = await response.json();
      if (response_data.server_status !== 200) {
        alert(response_data.message);
        return;
      } else {
        navigate('/get-results', { state: { verdict_data: response_data.analysis } });
      }
    } catch (error) {
      console.error('Error verifying:', error);
      alert("We could not process your request currently. Please try again later.");
    } finally {
      hideLoading();
    }
  };

  const handlePaste = () => {
    navigator.clipboard.readText().then((clipText) => {
      setFormData(prevData => ({ ...prevData, text: clipText }));
    });
  };

  const handleInput = () => {
    if (textareaRef.current) {
      const newHeight = Math.min(textareaRef.current.scrollHeight, 80);
      textareaRef.current.style.height = `${newHeight}px`;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleInput();
    }
  };

  const handleBackButtonClick = () => {
    navigate('/');
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [formData.text]);

  return (
    <div className="containerChatVerify">
      <img src="backbutton.svg" alt="Back" className="back-icon" onClick={handleBackButtonClick} />
      <div className="inputBox">
        <textarea
          ref={textareaRef}
          value={formData.text}
          onChange={(e) => setFormData(prevData => ({ ...prevData, text: e.target.value }))}
          onKeyDown={handleKeyPress}
          className="inputTextArea"
          placeholder="Your message..."
          rows="1"
        />
        <button className="paste-button" onClick={handlePaste}>Paste</button>
      </div>
      <button className="cta-button" onClick={handleVerifyNow}>Verify Now</button>
    </div>
  );
};

export default VerifyText;
